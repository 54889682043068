import {Subject} from "../util/Types";

export const subjects: Subject[] = [
    {
        name: 'Deutsch',
        color: '#800000',
        code: 'deutsch'
    },
    {
        name: 'Mathematik',
        color: '#000080',
        code: 'mathe'
    },
    {
        name: 'HSU',
        color: '#003300',
        code: 'hsu'
    },
    {
        name: 'Englisch',
        color: '#5c00e6',
        code: 'englisch'
    },
    {
        name: 'Religion/Ethik',
        color: '#c1c1a4',
        code: 'religion'
    },
    {
        name: 'Musik',
        color: '#ffcc00',
        code: 'musik'
    },
    {
        name: 'Kunst',
        color: '#ff33cc',
        code: 'kunst'
    },
    {
        name: 'Sport',
        color: '#0099ff',
        code: 'sport'
    },
    {
        name: 'Fördermaterial',
        color: '#e65c00',
        code: 'individual'
    }
];

export const getSubjectColor = (code: string): string => {
    for (let subject of subjects) {
        if (subject.code === code) {
            return subject.color;
        }
    }

    return '';
};
